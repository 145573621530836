module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"JoinFlow","short_name":"Flow","start_url":"/","background_color":"#12c2ff","theme_color":"#12c2ff","display":"minimal-ui","icon":"src/images/flowfavicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8493a6233c3bc50486fde63b683da9a9"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
